import { useDispatch } from 'react-redux';
import { DATE_FORMATS, formatDateTime } from '@belong/common';
import Button, { BUTTON_TYPES } from 'components/Button/Button';
import Field from 'components/Field/Field';
import Form from 'components/Form/Form';
import { InputFinalFormAdapter } from 'components/Input/Input';
import { SELECTOR_TYPES, SelectorFinalFormAdapter } from 'components/Selector/Selector';
import { isSameDay } from 'date-fns';
import { Box, Text } from 'design-system';
import arrayMutators from 'final-form-arrays';
import { Row, Col } from 'forkedlibraries/react-bootstrap';
import Condition from 'formcomponents/Condition/Condition';
import { AdditionalInfoSection } from 'formcomponents/RentalStatusInfo/AdditionalInfoSection/AdditionalInfoSection';
import { AvailableForRentOnASpecificDate } from 'formcomponents/RentalStatusInfo/AvailableForRentOnASpecificDate/AvailableForRentOnASpecificDate';
import { MoveOutSpecificDateFields } from 'formcomponents/RentalStatusInfo/MoveOutSpecificDateFields/MoveOutSpecificDateFields';
import { RentYourNextHomeFields } from 'formcomponents/RentalStatusInfo/RentYourNextHomeFields/RentYourNextHomeFields';
import { StandaloneMoveOutSection } from 'formcomponents/RentalStatusInfo/StandaloneMoveOutSection/StandaloneMoveOutSection';
import { StandaloneVacantMoveOutSection } from 'formcomponents/RentalStatusInfo/StandaloneVacantMoveOutSection/StandaloneVacantMoveOutSection';
import FormLayout from 'layouts/FormLayout/FormLayout';
import { ContentLayout } from 'layouts/SingleColumnFlowLayout/ContentLayout/ContentLayout';
import { isEqual } from 'lodash-es';
import { RentalStatus, MoveOutOptions, AvailableForRentOptions, NextHomePlans } from 'models/enums';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { updateUnitOccupancy } from 'store/redux/homeowner-accounts/actions';
import { formatString } from 'strings';
import { STRINGS } from 'strings/occupancy-standalone.strings';
import { parseMMYYDateToFirstDayOfMonth, parseDateTimeInputStringMMDDYY } from 'utils/dateTimeUtils';
import { required, validPastOrTodayMMDDYYDate } from 'utils/validation';
import { plansHaveChangedPronoun, plansHaveChangedVerb, getIsDayInThePast } from '../consts';

const getTitle = (finalizingPlans, plansHaveChanged, name, onboardingInfo = {}) => {
  if (finalizingPlans) {
    return formatString(STRINGS['landing_page.finalizing_plans']);
  }

  if (plansHaveChanged) {
    return formatString(STRINGS['landing_page.plans_changed'], { name });
  }

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getYear();
  const occupied = onboardingInfo.rentalStatus !== RentalStatus.Vacant;

  if (occupied) {
    // Occupied case
    const pronoun = onboardingInfo.rentalStatus === RentalStatus.OwnerOccupied ? 'you' : 'they';
    if (onboardingInfo.moveoutOption === MoveOutOptions.OnSpecificDate) {
      const moveoutOn = new Date(onboardingInfo.moveoutOn);
      const ordinalDate = numeral(moveoutOn.getDate()).format('0o');
      if (moveoutOn > today || isSameDay(moveoutOn, today)) {
        const dateOrToday = isSameDay(moveoutOn, today) ? 'today' : `on the ${ordinalDate}`;
        return formatString(STRINGS['landing_page.move_out.same_day_or_after'], { pronoun, dateOrToday, name });
      } else {
        return formatString(STRINGS['landing_page.move_out.day_in_the_past'], { pronoun, date: ordinalDate, name });
      }
    } else if (onboardingInfo.moveoutOption === MoveOutOptions.InDateRange) {
      const moveoutStartOn = new Date(onboardingInfo.moveoutStartOn);
      const moveoutMonth = moveoutStartOn.getMonth();
      const moveoutYear = moveoutStartOn.getYear();

      if (moveoutYear > currentYear || (moveoutYear === currentYear && moveoutMonth > currentMonth)) {
        return formatString(STRINGS['landing_page.move_out.month_in_the_future'], {
          pronoun,
          month: formatDateTime({ dateTime: moveoutStartOn, format: DATE_FORMATS.MONTH }),
          name,
        });
      } else if (moveoutYear === currentYear && moveoutMonth === currentMonth) {
        return formatString(STRINGS['landing_page.move_out.same_month'], { pronoun, name });
      } else {
        return formatString(STRINGS['landing_page.move_out.month_in_the_past'], { pronoun, name });
      }
    }
  } else {
    // Vacant, but not available for rent case
    const onSpecificDate = onboardingInfo.availableForRentOption === AvailableForRentOptions.OnSpecificDate;
    const availableForRentOn = new Date(onboardingInfo.availableForRentOn);

    if (onSpecificDate) {
      const ordinalDate = numeral(availableForRentOn.getDate()).format('0o');
      const dateOrToday = isSameDay(availableForRentOn, today) ? 'today' : `on the ${ordinalDate}`;

      if (availableForRentOn > today || isSameDay(availableForRentOn, today)) {
        return formatString(STRINGS['landing_page.available_on.same_day_or_after'], { dateOrToday, name });
      } else {
        return formatString(STRINGS['landing_page.available_on.day_in_the_past'], { date: ordinalDate, name });
      }
    } else {
      // Case for specific month
      const availableOnMonth = availableForRentOn.getMonth();
      if (availableOnMonth > currentMonth) {
        return formatString(STRINGS['landing_page.available_on.month_in_the_future'], {
          month: formatDateTime({ dateTime: availableForRentOn, format: DATE_FORMATS.MONTH }),
          name,
        });
      } else if (availableOnMonth === currentMonth) {
        return formatString(STRINGS['landing_page.available_on.same_month'], { name });
      } else {
        return formatString(STRINGS['landing_page.available_on.month_in_the_past'], { name });
      }
    }
  }

  return '';
};

export const OccupancyStandaloneLandingPage = ({
  history,
  location,
  match,
  employee,
  unit,
  user,
  startLoader,
  stopLoader,
}) => {
  const dispatch = useDispatch();

  const params = queryString.parse(location.search);
  const finalizingPlansFromParams = params?.finalizingPlans === '1';
  // finalizingPlansFromParams always counts as plansHaveChanged
  const plansHaveChangedFromParams = params?.plansHaveChanged === '1' || finalizingPlansFromParams;

  const { rentalStatus } = unit?.basicInfo?.onboardingInfo || {};
  const pronoun = rentalStatus === RentalStatus.OwnerOccupied ? 'you' : 'they';
  const isDayInThePast = getIsDayInThePast(unit?.basicInfo?.onboardingInfo);

  const getInitialValues = () => {
    const initialValues = {
      occupancyStandaloneLandingPage: {},
      plansHaveChanged: plansHaveChangedFromParams,
    };

    if (unit?.basicInfo?.onboardingInfo) {
      const { onboardingInfo } = unit.basicInfo;
      initialValues.occupancyStandaloneLandingPage = structuredClone(unit.basicInfo.onboardingInfo);

      if (onboardingInfo.moveoutDestinationNotSure) {
        initialValues.occupancyStandaloneLandingPage.moveoutDestination = {
          checkboxValue: true,
        };
      } else {
        initialValues.occupancyStandaloneLandingPage.moveoutDestination = {
          inputValue: onboardingInfo.moveoutDestination,
        };
      }

      if (onboardingInfo.moveoutPlan === NextHomePlans.NotSure) {
        initialValues.occupancyStandaloneLandingPage.moveoutPlan = {
          checkboxValue: true,
        };
      } else {
        initialValues.occupancyStandaloneLandingPage.moveoutPlan = {
          inputValue: onboardingInfo.moveoutPlan,
        };
      }

      if (onboardingInfo.moveoutOption === MoveOutOptions.InDateRange) {
        initialValues.occupancyStandaloneLandingPage.moveOutInAMonth = formatDateTime({
          dateTime: onboardingInfo.moveoutStartOn,
          format: DATE_FORMATS.SHORT,
        });
      }

      if (onboardingInfo.availableForRentOption === AvailableForRentOptions.InCertainMonth) {
        initialValues.occupancyStandaloneLandingPage.availableInAMonth = formatDateTime({
          dateTime: onboardingInfo.availableForRentOn,
          format: DATE_FORMATS.SHORT,
        });
      }
    }

    return initialValues;
  };

  const handleSubmit = async (values) => {
    if (!values.plansHaveChanged) {
      history.push(`${match.url}/success?plansHaveChanged=0`);
      return;
    }

    const occupied = rentalStatus !== RentalStatus.Vacant;
    const data = structuredClone(values.occupancyStandaloneLandingPage);
    const model = {};

    if (occupied) {
      model.moveoutOption = data.moveoutOption;
      if (data.moveoutOption === MoveOutOptions.OnSpecificDate) {
        model.moveoutOn = parseDateTimeInputStringMMDDYY(data.moveoutOn);
        model.moveoutComments = data.moveoutComments;
      } else if (data.moveoutOption === MoveOutOptions.InDateRange) {
        model.moveoutStartOn = data.moveoutStartOn;
        model.moveoutEndOn = data.moveoutEndOn;
        model.moveoutComments = data.moveoutComments;
      } else if (data.moveoutOption === MoveOutOptions.AlreadyDid) {
        model.moveoutOn = parseDateTimeInputStringMMDDYY(data.moveoutOn);
      }

      if (rentalStatus === RentalStatus.OwnerOccupied) {
        if (data.moveoutPlan?.inputValue) {
          model.moveoutPlan = data.moveoutPlan.inputValue;
          if (data.moveoutPlan?.inputValue === NextHomePlans.Rent) {
            model.moveoutRentingStatus = data.moveoutRentingStatus;
          } else if (data.moveoutPlan?.inputValue === NextHomePlans.Own) {
            model.moveoutOwningStatus = data.moveoutOwningStatus;
          }
        } else if (data.moveoutPlan?.checkboxValue) {
          model.moveoutPlan = NextHomePlans.NotSure;
        }

        if (data.moveoutDestination?.inputValue) {
          model.moveoutDestination = data.moveoutDestination.inputValue;
        } else if (data.moveoutDestination?.checkboxValue) {
          model.moveoutDestinationNotSure = true;
        }
      }
    } else {
      model.availableForRentOption = data.availableForRentOption;

      if (data.availableForRentOption === AvailableForRentOptions.OnSpecificDate) {
        model.availableForRentOn = parseDateTimeInputStringMMDDYY(data.availableForRentOn);
        model.availableForRentComments = data.availableForRentComments;
      } else if (data.availableForRentOption === AvailableForRentOptions.InCertainMonth) {
        model.availableForRentOn = parseMMYYDateToFirstDayOfMonth(data.availableInAMonth);
        model.availableForRentComments = data.availableForRentComments;
      } else if (data.availableForRentOption === AvailableForRentOptions.AlreadyIs) {
        model.availableForRentOn = parseDateTimeInputStringMMDDYY(data.availableForRentOn);
      }
    }

    model.plansHaveChangedComments = data.plansHaveChangedComments;
    startLoader();
    try {
      await dispatch(updateUnitOccupancy(unit.basicInfo.unitId, model));
      history.push(`${match.url}/success?plansHaveChanged=1`);
    } catch (e) {
      console.error(e);
    }
    stopLoader();
  };

  return (
    <ContentLayout
      title={
        <>
          <div>
            <Text>
              {getTitle(
                finalizingPlansFromParams,
                plansHaveChangedFromParams,
                user?.firstName,
                unit?.basicInfo?.onboardingInfo
              )}
            </Text>
          </div>
          <Text fontSize="body" fontWeight="normal">
            No longer in need of Belong’s services?{' '}
            <Button
              buttonType={BUTTON_TYPES.TEXT}
              to={`${match.url}/no-longer-need-services`}
              style={{ display: 'inline-block' }}
            >
              LET US KNOW
            </Button>
          </Text>
        </>
      }
      employee={employee}
    >
      <FormLayout>
        <Form
          mutators={{
            ...arrayMutators,
          }}
          initialValuesEqual={(values1, values2) => isEqual(values1, values2)}
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          getFormBottomBar={(formProps, nextButtonProps) => (
            <FormLayout.BottomBar
              ctaProps={{
                label: 'Next',
              }}
              nextButtonWrapperProps={nextButtonProps}
            />
          )}
          getForm={({ values }) => {
            // Name only to use the reusable fields in the same way
            const name = 'occupancyStandaloneLandingPage';

            return (
              <>
                <Row>
                  {!plansHaveChangedFromParams && (
                    <Col xs={12}>
                      <Box mb={values.plansHaveChanged && '2xl'}>
                        <Field
                          name="plansHaveChanged"
                          component={SelectorFinalFormAdapter}
                          validate={required}
                          buttons={
                            isDayInThePast
                              ? [
                                  { label: `${plansHaveChangedPronoun[rentalStatus]} did!`, key: false },
                                  { label: 'No, not yet.', key: true },
                                ]
                              : [
                                  { label: `Sure ${plansHaveChangedVerb[rentalStatus]}!`, key: false },
                                  { label: 'Plans have changed.', key: true },
                                ]
                          }
                          type={SELECTOR_TYPES.MEDIUMTEXTBUTTON}
                        />
                      </Box>
                    </Col>
                  )}
                </Row>

                <Condition when="plansHaveChanged" is>
                  {[RentalStatus.OwnerOccupied, RentalStatus.FamilyOccupied].includes(rentalStatus) && (
                    <StandaloneMoveOutSection
                      name={name}
                      onboardingInfo={unit?.basicInfo?.onboardingInfo}
                      rentalStatus={rentalStatus}
                    />
                  )}

                  {rentalStatus === RentalStatus.OwnerOccupied && (
                    <Condition when={`${name}.moveoutOption`} hasValue>
                      <Condition when={`${name}.moveoutOption`} isNot={MoveOutOptions.AlreadyDid}>
                        <RentYourNextHomeFields name={name} />
                      </Condition>
                    </Condition>
                  )}

                  {[RentalStatus.OwnerOccupied, RentalStatus.FamilyOccupied].includes(rentalStatus) && (
                    <Condition when={`${name}.moveoutOption`} hasValue>
                      <Condition when={`${name}.moveoutOption`} isNot={MoveOutOptions.AlreadyDid}>
                        <AdditionalInfoSection name={name} />
                      </Condition>
                    </Condition>
                  )}

                  {rentalStatus === RentalStatus.Vacant && <StandaloneVacantMoveOutSection name={name} />}

                  <Condition when={`${name}.moveoutOption`} is={MoveOutOptions.AlreadyDid}>
                    <FormLayout.Section sectionTitle={`Oh great! When did ${pronoun} move out?`}>
                      <MoveOutSpecificDateFields
                        name={name}
                        extraMoveOutOptions={[MoveOutOptions.AlreadyDid]}
                        extraValidation={validPastOrTodayMMDDYYDate}
                      />
                    </FormLayout.Section>
                  </Condition>
                  <Condition when={`${name}.availableForRentOption`} is={AvailableForRentOptions.AlreadyIs}>
                    <FormLayout.Section sectionTitle="Oh great! When did your home become available?">
                      <AvailableForRentOnASpecificDate name={name} extraValidation={validPastOrTodayMMDDYYDate} />
                    </FormLayout.Section>
                  </Condition>

                  <Condition when={`${name}.availableForRentOption`} hasValue>
                    <FormLayout.Section sectionTitle="Mind if we ask, why the change?">
                      <Field
                        component={InputFinalFormAdapter}
                        name={`${name}.plansHaveChangedComments`}
                        placeholder="Reason(s) (OPTIONAL)"
                        textarea
                      />
                    </FormLayout.Section>
                  </Condition>
                  <Condition when={`${name}.moveoutOption`} hasValue>
                    <FormLayout.Section sectionTitle="Mind if we ask, why the change?">
                      <Field
                        component={InputFinalFormAdapter}
                        name={`${name}.plansHaveChangedComments`}
                        placeholder="Reason(s) (OPTIONAL)"
                        textarea
                      />
                    </FormLayout.Section>
                  </Condition>
                </Condition>
              </>
            );
          }}
        />
      </FormLayout>
    </ContentLayout>
  );
};

OccupancyStandaloneLandingPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  employee: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  startLoader: PropTypes.func.isRequired,
  stopLoader: PropTypes.func.isRequired,
};
