import { filter, find, includes, indexOf } from 'lodash-es';
import { PostInspectionFlowStep, PostInspectionGroup } from 'models/enums/index';

export const PostInspectionFlowGroupConfig = [
  {
    name: PostInspectionGroup.Improvements,
    key: 'improvements',
    steps: [
      PostInspectionFlowStep.ImprovementsGetStarted,
      PostInspectionFlowStep.ImprovementsFeatures,
      PostInspectionFlowStep.ImprovementsResponsibility,
      PostInspectionFlowStep.ImprovementsQuality,
      PostInspectionFlowStep.ImprovementsApproval,
      PostInspectionFlowStep.ImprovementsHomeownerFixingCompletionDate,
    ],
  },
  {
    name: PostInspectionGroup.Pricing,
    key: 'pricing',
    steps: [
      PostInspectionFlowStep.PricingGetStarted,
      PostInspectionFlowStep.PricingSupplyAndDemand,
      PostInspectionFlowStep.PricingSeasonality,
      PostInspectionFlowStep.PricingWaysToShowYourHome,
      PostInspectionFlowStep.PricingRelatedListings,
      PostInspectionFlowStep.PricingInitialListingPrice,
      PostInspectionFlowStep.PricingCheckInPrice,
      PostInspectionFlowStep.PricingStrategy,
      PostInspectionFlowStep.PricingSmoothPayment,
    ],
  },
];

export const getGroupForStep = (stepName) =>
  find(PostInspectionFlowGroupConfig, (config) => includes(config.steps, stepName));

export const PostInspectionFlowStepConfig = [
  {
    name: PostInspectionFlowStep.ImprovementsGetStarted,
    displayName: 'Intro',
    key: 'get-started',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsGetStarted),
    stepDataNotRequired: true,
  },
  {
    name: PostInspectionFlowStep.ImprovementsFeatures,
    displayName: 'Features',
    key: 'features',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsFeatures),
  },
  {
    name: PostInspectionFlowStep.ImprovementsResponsibility,
    displayName: 'Responsibility',
    key: 'responsibility',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsResponsibility),
  },
  {
    name: PostInspectionFlowStep.ImprovementsQuality,
    displayName: 'Quality',
    key: 'quality',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsQuality),
    stepDataNotRequired: true,
  },
  {
    name: PostInspectionFlowStep.ImprovementsApproval,
    displayName: 'Approval',
    key: 'approval',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsApproval),
  },
  {
    name: PostInspectionFlowStep.ImprovementsHomeownerFixingCompletionDate,
    displayName: 'Homeowner Fixes',
    key: 'homeowner-fixes',
    group: getGroupForStep(PostInspectionFlowStep.ImprovementsHomeownerFixingCompletionDate),
    disabled: true,
  },
  {
    name: PostInspectionFlowStep.PricingGetStarted,
    displayName: 'Intro',
    key: 'get-started',
    group: getGroupForStep(PostInspectionFlowStep.PricingGetStarted),
  },
  {
    name: PostInspectionFlowStep.PricingSeasonality,
    displayName: 'Seasonality',
    key: 'seasonality',
    group: getGroupForStep(PostInspectionFlowStep.PricingSeasonality),
  },
  {
    name: PostInspectionFlowStep.PricingWaysToShowYourHome,
    displayName: 'Marketing',
    key: 'ways-to-show-your-home',
    group: getGroupForStep(PostInspectionFlowStep.PricingWaysToShowYourHome),
  },
  {
    name: PostInspectionFlowStep.PricingSupplyAndDemand,
    displayName: 'The Market',
    key: 'supply-demand',
    group: getGroupForStep(PostInspectionFlowStep.PricingSupplyAndDemand),
  },
  {
    name: PostInspectionFlowStep.PricingRelatedListings,
    displayName: 'Current Supply',
    key: 'related-listings',
    group: getGroupForStep(PostInspectionFlowStep.PricingRelatedListings),
  },
  {
    name: PostInspectionFlowStep.PricingInitialListingPrice,
    displayName: 'Listing Pricing',
    key: 'initial-listing-price',
    group: getGroupForStep(PostInspectionFlowStep.PricingInitialListingPrice),
  },
  {
    name: PostInspectionFlowStep.PricingCheckInPrice,
    displayName: 'Floor Price',
    key: 'check-in-price',
    group: getGroupForStep(PostInspectionFlowStep.PricingCheckInPrice),
  },
  {
    name: PostInspectionFlowStep.PricingStrategy,
    displayName: 'Strategy',
    key: 'strategy',
    group: getGroupForStep(PostInspectionFlowStep.PricingStrategy),
  },
  {
    name: PostInspectionFlowStep.PricingSmoothPayment,
    displayName: 'Smooth Payments™',
    key: 'smooth-payments',
    group: getGroupForStep(PostInspectionFlowStep.PricingSmoothPayment),
  },
];

export const getStepByName = (name) => find(PostInspectionFlowStepConfig, { name });

export const extractAndSortStepsInGroup = (steps, groupKey) => {
  const groupSteps = filter(steps, (step) => step.group?.key === groupKey);

  const groupConfig = find(PostInspectionFlowGroupConfig, { key: groupKey });

  groupSteps.sort((a, b) => indexOf(groupConfig.steps, a.name) - indexOf(groupConfig.steps, b.name));

  const nonHiddenSteps = groupSteps.filter((step) => step.isHidden !== true);

  return nonHiddenSteps;
};
